export const platformConfig = {
   /* `local` or `beta` or `develop` or `staging` or `prod` */
  local: {
    baseApiURL: "https://briind-api.fortelogic.in",
    elasticSearchUrl: "https://briind-api.fortelogic.in",
    webAppURL: "http://localhost:4200",
    facebookAppId: "5077721412343492",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize:  1024,
    cutomDomain: '',
    stripeKey: "pk_test_51ISobPFfJiy2k2XhkeJLdmsJEa04X1qyM3Du1fVRsVmqjs2JLuxIxim3wMbDMQMqsoTHUermX3oKVgTd4YDxi8xo00xCwr8O1b",
    firebase: {
      apiKey: "AIzaSyC8X8bP57StzkIIgqTL4M2so2l94OPR478",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    },
    credentials: {
      appId: '92207',
      authKey: 'CGOvJvwxAVC9SNd',
      authSecret: 'YnpSmdORqajOmMt',
      accountKey: 'n_okhhyVzY9twZBkba_z'
    },
    appConfig: {
      chatProtocol: {
        active: 2
      },
      streamManagement: {
        enable: true
      },
      debug: {
        mode: 1,
        file: null
      }
    }
  },
  beta: {
    baseApiURL: "https://briind-api-test.fortelogic.in",
    elasticSearchUrl: "https://api.briind.com",
    webAppURL: "https://beta-briind.fortelogic.in",
    facebookAppId: "392396485062988",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize:  1024,
    cutomDomain: '.beta.briind.fortelogic.in',
    stripeKey: "pk_test_51ISobPFfJiy2k2XhkeJLdmsJEa04X1qyM3Du1fVRsVmqjs2JLuxIxim3wMbDMQMqsoTHUermX3oKVgTd4YDxi8xo00xCwr8O1b",
    firebase: {
      apiKey: "AIzaSyC8X8bP57StzkIIgqTL4M2so2l94OPR478",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    },
    credentials: {
      appId: '',
      authKey: '',
      authSecret: '',
      accountKey: ''
    },
    appConfig: {
      chatProtocol: {
        active: 2
      },
      streamManagement: {
        enable: true
      },
      debug: {
        mode: 1,
        file: null
      }
    }
  },
  develop: {
    baseApiURL: "https://briind-api.fortelogic.in",
    webAppURL: "https://briind.fortelogic.in",
    elasticSearchUrl: "https://briind-api.fortelogic.in",

    // baseApiURL: "https://api.briind.com",
    // webAppURL: "https://app.briind.com",
    // elasticSearchUrl: "https://api.briind.com",

    facebookAppId: "5077721412343492",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize:  1024,
    cutomDomain: '.briind.fortelogic.in',
    stripeKey: "pk_test_51ISobPFfJiy2k2XhkeJLdmsJEa04X1qyM3Du1fVRsVmqjs2JLuxIxim3wMbDMQMqsoTHUermX3oKVgTd4YDxi8xo00xCwr8O1b",
    firebase: {
      apiKey: "AIzaSyC8X8bP57StzkIIgqTL4M2so2l94OPR478",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    },
    credentials: {
      appId: '92207',
      authKey: 'CGOvJvwxAVC9SNd',
      authSecret: 'YnpSmdORqajOmMt',
      accountKey: 'n_okhhyVzY9twZBkba_z'
    },
    appConfig: {
      chatProtocol: {
        active: 2
      },
      streamManagement: {
        enable: true
      },
      debug: {
        mode: 1,
        file: null
      }
    }
  },
  staging: {
    // baseApiURL: "https://api.staging.briind.com",
    baseApiURL: "https://api.briind-staging.ileafsolutions.com",
    webAppURL: "https://briind-staging.ileafsolutions.com",
    elasticSearchUrl: "https://api.briind-staging.ileafsolutions.com",
    facebookAppId: "5077721412343492",
    googleClientId: "1063909761046-unhln4miacp7v618a12sa0rleshpi9ip.apps.googleusercontent.com",
    postFileSize:  1024,
    cutomDomain: '.briind-development.ileafsolutions.com',
    stripeKey: "pk_test_51ISobPFfJiy2k2XhkeJLdmsJEa04X1qyM3Du1fVRsVmqjs2JLuxIxim3wMbDMQMqsoTHUermX3oKVgTd4YDxi8xo00xCwr8O1b",
    firebase: {
      apiKey: "AIzaSyC8X8bP57StzkIIgqTL4M2so2l94OPR478",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    },
    credentials: {
      appId: '92207',
      authKey: 'CGOvJvwxAVC9SNd',
      authSecret: 'YnpSmdORqajOmMt',
      accountKey: 'n_okhhyVzY9twZBkba_z'
    },
    appConfig: {
      chatProtocol: {
        active: 2
      },
      streamManagement: {
        enable: true
      },
      debug: {
        mode: 1,
        file: null
      }
    }
  },
  prod: {
    baseApiURL: "https://api.briind.com",
    // baseApiURL: "https://api.staging.briind.com",
    webAppURL: "https://app.briind.com",  
    elasticSearchUrl: "https://app.briind.com",
    facebookAppId: "392396485062988",
    googleClientId: "355559133288-uekn7d9bvt70oqufn921a3r66uav20jn.apps.googleusercontent.com",
    postFileSize:  1024,
    cutomDomain: '.app.briind.com',
    stripeKey: "pk_live_51ISobPFfJiy2k2XhgikPaPNNwv66S5qx2rpx7tS0LWaqoq5TarNRt6rRYh41j3As6veo6NjkiPa85YzQa34UuCQQ00Sbo4clkB",
    firebase: {
      apiKey: "AIzaSyC8X8bP57StzkIIgqTL4M2so2l94OPR478",
      authDomain: "briind-web-push.firebaseapp.com",
      databaseURL: "https://briind-web-push-default-rtdb.firebaseio.com",
      projectId: "briind-web-push",
      storageBucket: "briind-web-push.appspot.com",
      messagingSenderId: "214967122538",
      appId: "1:214967122538:web:876b8ee5d986720923e813",
      measurementId: "G-0HLPXFCSMR"
    },
    credentials: {
      appId: '92207',
      authKey: 'CGOvJvwxAVC9SNd',
      authSecret: 'YnpSmdORqajOmMt',
      accountKey: 'n_okhhyVzY9twZBkba_z'
    },
    appConfig: {
      chatProtocol: {
        active: 2
      },
      streamManagement: {
        enable: true
      },
      debug: {
        mode: 1,
        file: null
      }
    }
  }
};
